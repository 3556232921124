import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  MobileMenu:boolean = false;
  
  constructor(public sidebar:AppComponent) { }

  ngOnInit() {
    this.sidebar.MobileMenu = false;
  }

}
