import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']

})
export class PortfolioComponent implements OnInit {
  
  portfolioPopup: Boolean = false;
  portfolioActiveTab: any;
  
  TagText:string = "All";
  constructor(public sidebar: AppComponent) { }

  ngOnInit() {
    this.TagText = "All";
    this.sidebar.MobileMenu = false;
  }


  openModal(id) {
    if (!this.portfolioPopup) {
      this.portfolioPopup = true;
    }
    this.portfolioActiveTab = id;
  }
  closeModal() {
    this.portfolioPopup = false;
  }

}
